<template>
    <div>
        <div class="card-toolbar mb-5">
            <button v-if="$can('manufacturers.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('manufacturers.new_manufacturer') }}
            </button>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_name">{{$t('manufacturers.name')}}</label>
                            <input v-model="filters.name" type="text" id="f_name" class="form-control">
                            
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('manufacturers.category')}}</label>
                            <treeselect
                                    :options="categories"
                                    :load-options="loadOptions"
                                    :multiple="false"
                                    :value="filters.category"
                                    @input="updateFilterValue"
                                    :searchable="true"
                                    :show-count="true"
                                    :no-children-text="$t('No_sub_options')"
                                    :no-options-text="$t('No_options_available')"
                                    :no-results-text="$t('No_results_found')"
                                    :placeholder="$t('Select')">
                            </treeselect>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.is_active" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{$t('search')}}</span>
                                </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{$t('reset_search')}}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <div class="d-flex justify-content-end mb-5">
                    <custom-export-data v-if="$can('manufacturers.export_data')" :data-list="dataList" :file-name="fileName" :fields="json_fields"></custom-export-data>
                </div>
                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <b-form-checkbox v-if="$can('manufacturers.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                        <b-form-checkbox v-else size="lg" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('manufacturers.update')" color="blue darken-2" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('manufacturers.delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

        <b-modal ref="modal" hide-footer :title="$t('manufacturers.manufacturer')">
          <div class="row">
            <div class="col-lg-6 mb-5">
              <label for="name">{{$t('manufacturers.name')}}<span class="text-danger">*</span></label>
              <input v-model="data.name" type="text" class="form-control" id="name" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('manufacturers.name')">
              <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.name[0] }}
                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{$t('manufacturers.category')}}</label>
              <treeselect
                  :options="categories"
                  :load-options="loadOptions"
                  :multiple="true"
                  :value="data.categories"
                  :searchable="true"
                  @input="updateValue"
                  :show-count="true"
                  :no-children-text="$t('No_sub_options')"
                  :no-options-text="$t('No_options_available')"
                  :no-results-text="$t('No_results_found')"
                  :placeholder="$t('Select')">
              </treeselect>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{$t('manufacturers.upload_image')}}</label>
              <div class="form-group">
                <upload-file
                    @file="listenerAttachment"
                    :inner-id="'image'"
                    :placeholder="$t('manufacturers.upload_image')"
                    :upload="dir_upload"
                    :start-link="'base'"
                    v-bind:valueprop="{name:'logo',path:image_url}" v-if="reloadUploadAttachment">
                </upload-file>
              </div>
            </div>
            <div class="col-lg-6 mb-5">
              <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
            </div>
            <div class="col-lg-12 mb-5">
              <label for="notes">{{$t('manufacturers.notes')}}</label>
              <textarea v-model="data.notes" type="text" class="form-control" id="notes" :placeholder="$t('manufacturers.notes')" ></textarea>
            </div>
          </div>
            <div class="d-flex justify-content-end">
                <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>
                <b-button class="mt-2 mr-1" variant="primary" @click="save">{{$t('save')}}</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index",
        components: {},
        data() {
            return {
                mainRoute: 'settings/manufacturers',
                subMainRoute: 'settings/manufacturer',
                mainRouteDependency: 'base/dependency',
                dir_upload: 'manufacturers',
                showAdvancedSearch: false,
                filters: {
                    name: '',
                    category: null,
                    is_active: '',
                },
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                columns: ['name', 'user_name', 'created_at', 'status', 'actions'],

                idEditing: null,
                data: {
                    id: null,
                    name: null,
                    notes: null,
                    categories: [],
                    is_active: true,
                    image: null,
                },

                disabledButton: false,
                validation: [],
                categories: [],
                // categories: [
                //     {
                //         id: 1, label: 'a',
                //         children: [
                //             {id: 2, label: 'aa',},
                //             {id: 3, label: 'ab',}
                //         ],
                //     },
                //     {id: 4, label: 'b',},
                //     {id: 5, label: 'c',}
                //     ],
                image_url: null,
                reloadUploadAttachment: true,
                dataList : [],
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('manufacturers.name')] = 'name';
                fields[this.$t('manufacturers.user')] = 'user_name';
                fields[this.$t('created_at')] = 'created_at';
                fields[this.$t('status')] = 'is_active';
                return fields;
            },
            fileName : function () {
                return this.$t('manufacturers.manufacturers');
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('manufacturers.name'),
                        user_name: that.$t('manufacturers.user'),
                        created_at: that.$t('created_at'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        watch: {
            // 'item.parent_id': function (val) {
            //     if (val) {
            //         let _row = this.parent_list.filter(row => row.id == val);
            //         if (_row && _row[0]) {
            //             _row = _row[0];
            //             this.item.key = _row.key;
            //         }
            //     } else {
            //         this.item.key = '';
            //     }
            // },
        },
        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Manufacturers")}]);
            this.getCategories();
            this.$root.$on('bv::modal::hide', () => {
                this.afterSave();
            });
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = '';
                this.filters.category = null;
                this.filters.is_active = '';

                this.$refs.table.refresh();
            },
            editItem(item) {
                this.reloadUploadAttachment = false;
                this.idEditing = item.id;
                ApiService.get(this.mainRoute + "/" + item.id).then((response) => {
                    this.data.id = response.data.data.id;
                    this.data.name = response.data.data.name;
                    this.data.notes = response.data.data.notes;
                    this.data.categories = response.data.data.categories;
                    this.data.is_active = response.data.data.is_active;
                    this.data.image = response.data.data.image;
                    this.image_url = response.data.data.image_url;
                    this.showModal();
                    this.reloadUploadAttachment = true;
                }).catch((error) => {
                    this.$errorAlert(error)
                })
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error)
                })
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            showModal() {
                this.$refs['modal'].show()
                this.validation = null;
            },
            hideModal() {
                this.$refs['modal'].hide();
                this.afterSave();
                this.validation = null;
            },
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn');
            },
            save() {
                if (this.idEditing) {
                    this.saveEdit();
                } else {
                    this.saveCreate();
                }
            },
            saveCreate() {
                // this.data.is_active = this.data.is_active?1:0;
                ApiService.post(this.mainRoute, this.data).then((response) => {
                    this.$successAlert(response.data.message);
                    this.afterSave();
                    this.hideModal();
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },
            saveEdit() {
                this.data.id = this.idEditing;
                // this.data.is_active = this.data.is_active?1:0;
                ApiService.put(this.mainRoute + "/" + this.idEditing, this.data).then((response) => {
                    this.$successAlert(response.data.message);
                    this.afterSave();
                    this.hideModal();
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },
            afterSave() {

                this.getFetch();
                this.data = {
                    id: null,
                    name: null,
                    notes: null,
                    categories: null,
                    is_active: true,
                    image: null,
                };
                this.idEditing = null;
                this.image_url = null;

            },
            getCategories() {
                ApiService.get(this.mainRouteDependency + "/categories").then(response => {
                    this.categories = response.data.data;
                });
            },
            changeStatus(id, status) {
                ApiService.patch(this.subMainRoute + '/change-status/' + id, {
                    is_active: (status ? 1 : 0),
                }).then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },

            listenerAttachment(event) {
                if (event) {
                    this.image_url = event.pathDB;
                    this.data.image = event.name;
                }
            },

            updateValue(value) {
                this.data.categories = value
            },
            updateFilterValue(value) {
                this.filters.category = value
            },
            loadOptions() {}
        },
    };
</script>
